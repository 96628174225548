@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato);
html {
  height: 100%;
  margin: 0;
}

body {
  background: url('/imgs/background_tile.png') repeat;
  margin: 0;
  color: #fff;
  font-family: 'Lato', sans-serif;
  text-align: center;
  justify-content: center;
  min-width: 850px;
  height: 100%;
}

.game {
  position: relative;
}

.backdrop {
  position: absolute;
  top: 30%;
  left: 50%;
  min-width: 20rem;
  padding: 50;
  z-index: 20;
  margin: 0 auto;
}

.modal {
  position: relative;
  left: -50%;
  background-color: rgba(255,255,255,1);
  color:rgb(11, 11, 11);
  border-radius: 20px;
  border: solid;
  border-color: black;
  max-width: 20rem;
  margin: 0 auto;
  padding: 10px 20px 10px 25px;
  z-index: 20;
  text-align: left;
};


.communal-container, .results-container {
  position: relative;
  display: block;
  border: 0.2rem solid;
  border-radius: 1rem;
  margin: 1rem;
  overflow: auto;
  white-space: nowrap;
  max-width: 88rem;
  min-height: 8rem;
}

.table-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 30rem;
  justify-content: space-evenly;
  margin-top: 5rem;
  margin-bottom: 1rem;
}

.playerdisplay {
  position: relative;
  height: 8rem;
  width: 15rem;
}

.playercards {
  position: absolute;
  margin-left: 2rem;
}

.dimmed {
  -webkit-filter: brightness(50%);
          filter: brightness(50%)
}

.playerodds {
  position: absolute;
  top: 4.5rem;
  left: 2rem;
  z-index: 15;
  color: green;
}

.playerodds.left {
  left: 11rem;
}

.playerdisplaybox {
  position: relative;
  top: 3rem;
  /* border: 0.2rem solid;*/
  border-radius: 1rem;
  margin: 1rem;
  overflow: auto;
  white-space: nowrap;
  width: 13rem;
  height: 4rem;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  z-index: 10;
}

.disconnect {
    background-color: rgb(40,40,40);
    color: rgb(180, 180, 180)
}

.nick {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.playerdisplaybox > img.rmplayer {
  position: absolute;
  width: 10px;
  height: 10px;
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
  top: 6px;
  left: 12rem;
  display: block;
  cursor: pointer;
}

.playerdisplaybox > img.spider {
  position: absolute;
  width: 60px;
  height: 60px;
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
  top: -3px;
  left: -2px;
  display: block;
}

.betstack {
  position: absolute;
  left: -4rem;
  bottom: 0rem;
}

.betstack.left {
  left: 13rem;
}

.upper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.lower {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.table-containers, .results-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.communal-container {
  margin-top: 0;
}

.results-container {
  width: 40rem;
  min-height: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.tablecenter {
  border: 0.2rem dashed;
  border-radius: 1rem;
  border-color: black;
  margin: 0 1rem;
  min-height: 8.4rem;
  min-width: 29rem;
  padding: 0.3rem 0.5rem;
  display: flex;
  justify-content: flex-start;
}

.pot {
  margin-top: 1rem;
  bottom: 0px;
  display: inline;
}

/* Action buttons */

.action-container {
  /*margin-top: 10rem; */
	display: flex;
  flex-direction: row;
  vertical-align: bottom;
  justify-content: center;
  height: 170px;
  margin-left: 3rem;
}

.raise-container {
  position: absolute;
  width: 10rem;
  margin-left: 1rem;
  bottom: 0px;
}

.amount > input {
  font-size: 0.8rem;
  width: 9rem;
}

input.slider {
  font-size: 0.8rem;
  width: 9.5rem;
}

.valbutt {
  font-size: 0.8rem;
  min-width: 0rem;
  border-radius: 0.3rem;
  margin: 0.05rem;
  margin-bottom: 0.8rem;
  background: #cd2b1d;
  color: white;
  border: none;
  outline: none;
}

.actnbtn {
  position: relative;
  background: #cd2b1d;;
  color: white;
  font-size: 1.4rem;
  border: none;
  border-radius: 0.6rem;
  padding: 0.9rem;
  padding-top: 0.7rem;
  /*margin-top: 55px;*/
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 8rem;
  outline: none;
  height: 1.5rem;
  cursor: pointer;
  text-align: center;
  bottom: 0px;
}

.btn-cont {
  position: relative;
  height: 100%;
  width: 13rem;
}

.btn-cont > .actnbtn {
  position: absolute;
  bottom: 0px;
}

.raise-container > .actnbtn {
  margin-top: 10px;
  margin-left: 0;
}

.actncheck {
  position: absolute;
  left: 10px;
  width: 20px;
  cursor: pointer;
}

button.active-button {
  color: rgb(43, 83, 54);
  background: white;
}

.footer > button {
  background-color: #cd2b1d;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding : 0.5rem;
  min-width: 5rem;

}

.modalinput {
    width: 90%;
    padding: 2px 8px 5px 2px;
    border-width: 0 0 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #dee1e6, #fff) 1;
    font-size: 14px;
    outline: none;
    margin-bottom: 10px;
    margin-top: 10px;
    background: black;
    color: white;
}

.card {
  width: 5rem;
  height: auto;
  margin: 0.4rem;
  cursor: pointer;
}

.results-container p {
  font-size: 1.2rem;
}

.dealerbutton {
  position: absolute;
  top: 7rem;
  left: 0.1rem;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color:  rgb(205, 43, 30);;
  margin-top: 0;
  font-size: 1.5rem;
  z-index: 20;
  border: 0.05rem solid;
}

.left > .dealerbutton {
  left: 13rem;
}

.bottom > .dealerbutton {
  top: 3.1rem;
}

.countdownbox {
  position:relative;
  border: 0.1rem solid;
  border-radius: 0.4rem;
  border-color: black;
  height: 1rem;
  width: 10rem;
  top: 1.5rem;
  left: 2.5rem;
}

.countdownfill {
  background-color: greenyellow;
  width: 100%;
  height: 100%;
}

.stack {
	position: relative;
  margin: 0 auto;
  margin-bottom: 0.6rem;
	display: flex;
  flex-direction: column;
  justify-content: column-reverse;
  width: 7rem;
}

.stackamount {
  font-size: 0.8rem;
}

.coin-cont {
	position: relative;
	height: 5px;
}

.coin {
	position: relative;
	overflow: hidden;
	margin: 0 auto;
	width: 20px;
	height: 14px;
	border-radius: 10px/5px;
	background-color: rgba(160, 160, 160,1);
}

.coin:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 20px;
	height: 10px;
	border-radius: 10px/5px;
  background-color: rgba(0, 0, 0, 1);
	content: '';
}

.coin.five {
	background-color: rgb(238, 75, 58);
}

.coin.tw5 {
	background-color: rgb(5, 127, 80);
}

.coin.oneh {
	background-color: rgb(220, 70, 100);
}

.coin.fiveh {
	background-color: rgba(180, 0, 180, 1);
}

.coin.fiveh {
	background-color: rgba(232, 219, 52, 1);
}

.coin.twofk {
	background-color: rgba(109, 215, 211, 1);
}

.coin.tenk {
	background-color: rgba(0, 0, 0, 1);
}

.coin.ten.top:before {
  background-color: rgb(177, 72, 177);
}

.applycancel > .actnbtn {
  display: inline-block;
  width: 6rem;
  min-width: 6rem;
  margin-top: 2rem;
}

.inactive {
  cursor: none;
}

.sidemenu {
  position: absolute;
  background: #cd2b1d;;
  width: 48px;
  border-radius: 18px;
  top: 500px;
  left: 10px;
  z-index: 1;
}

.sidemenu > img {
  width: 32px;
  height: 32px;
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
        filter: invert(100%);
  margin: 0.4rem;
  cursor: pointer;
}

.sidemenu > img.enabled {
  -webkit-filter: invert(0%); /* safari 6.0 - 9.0 */
        filter: invert(0%);
}

.observers {
  position: absolute;
  font-family: -apple-system,BlinkMacSystemFont,open_sanslight,"Helvetica Neue",Helvetica,Arial,sans-serif!important;
  font-weight: 800;
  color: black;
  top: 23px;
  left: 22px;
  cursor: pointer;
  z-index: 2;
}

.speech-bubble {
	position: relative;
	background: white;
  border-radius: 1em;
  border: solid #cd2b1d;
  color: #cd2b1d;
  padding-left: 20px;
  padding-right: 10px;
  text-align: left;
  z-index: 30;
  height: 300px;
  min-width: 250px;

}

.speech-bubble:after {
	content: '';
	position: absolute;
	left: 0;
	top: 57%;
	width: 0;
	height: 0;
	border: 24px solid transparent;
	border-right-color: #cd2b1d;
	border-left: 0;
	margin-top: -24x;
	margin-left: -24px;
}

.jalla {
  float: right;
}

.obcont, .admcont, .setcont {
  position: absolute;
  left: 70px;
}

.obcont {
    top: -175px;
}

.setcont {
    top: -128px;
    min-width: 150px;
}

.admcont > .speech-bubble:after {
  top: 74%;
}

.admcont {
    top: -128px;
}

.settings {
  margin-bottom: 15px;
}

.settings > input {
  float: right;
}

.settings > .tsett {
  width: 50px;
  text-align: right;
}

.hidden {
  visibility: hidden;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 80px;
  height: 116px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin-top: 10px;
  margin-right: 10px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-front > img {
  width: 80px;
  height: 116px;
}

.flip-card-back > img {
  width: 80px;
  height: 116px;
}

#welcomescreen {
    margin: 10rem;
}

.introtext {
    text-align: justify;
    max-width: 25rem;
    margin: 0 auto;
}

#joingame {
    display: flow;
    flex-direction: row;
    background: white;
    text-align: left;
    width: 680px;
    padding: 25px 30px;
    font-family: -apple-system,BlinkMacSystemFont,open_sanslight,"Helvetica Neue",Helvetica,Arial,sans-serif!important;
    color: rgb(11, 11, 11);
    margin: auto;
    margin-top: 40px;
}

.joincnt {
    display: flow;
    flex-direction: column;
}

.jointitle {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.roominput, .nickinput {
    width: 90%;
    padding: 0 8px 5px 0;
    border-width: 0 0 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #dee1e6, #fff) 1;
    font-size: 14px;
    outline: none;
    margin-bottom: 10px;
}

.joinbtn {
    display: block;
    background-color: rgb(0, 116, 224);
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
}

