@import url('https://fonts.googleapis.com/css?family=Lato');

#welcomescreen {
    margin: 10rem;
}

.introtext {
    text-align: justify;
    max-width: 25rem;
    margin: 0 auto;
}

#joingame {
    display: flow;
    flex-direction: row;
    background: white;
    text-align: left;
    width: 680px;
    padding: 25px 30px;
    font-family: -apple-system,BlinkMacSystemFont,open_sanslight,"Helvetica Neue",Helvetica,Arial,sans-serif!important;
    color: rgb(11, 11, 11);
    margin: auto;
    margin-top: 40px;
}

.joincnt {
    display: flow;
    flex-direction: column;
}

.jointitle {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.roominput, .nickinput {
    width: 90%;
    padding: 0 8px 5px 0;
    border-width: 0 0 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #dee1e6, #fff) 1;
    font-size: 14px;
    outline: none;
    margin-bottom: 10px;
}

.joinbtn {
    display: block;
    background-color: rgb(0, 116, 224);
    color: white;
    width: fit-content;
    padding: 8px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
}
